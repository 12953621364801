import { useTranslation } from "react-i18next";

import ProjectUnitMix from "~/components/organisms/ProjectUnitMix";
import Section from "~/components/templates/SectionProject";
import { IProject } from "~/entities/project";
import { TUnitType } from "~/entities/unitType";
import { cn } from "~/utilities/cn";

export interface SectionProjectUnitMixProps {
  className?: string;
  id: string;
  title: string;
  description: string;
  project: IProject;
}

const SectionProjectUnitMix: React.FC<SectionProjectUnitMixProps> = ({
  className = "",
  id,
  title,
  description,
  project,
}) => {
  const { t } = useTranslation();

  if (!project) return null;

  // Calculate overall row data
  const calculateOverallRow = (): TUnitType | null => {
    if (project.unitTypes.length === 0) return null;

    let smallestSize = project.unitTypes[0].minArea;
    let largestSize = project.unitTypes[0].maxArea;
    const overallTotalUnits = project.unitTypes.reduce((total, unit) => {
      if (unit.minArea < smallestSize) smallestSize = unit.minArea;
      if (unit.maxArea > largestSize) largestSize = unit.maxArea;
      return total + unit.totalUnits;
    }, 0);

    return {
      id: "overall",
      slug: "overall",
      title: t("overall"),
      minArea: smallestSize,
      maxArea: largestSize,
      totalUnits: overallTotalUnits,
      availableUnits: 0,
      minPSF: 0,
      maxPSF: 0,
      maxPrice: 0,
      minPrice: 0,
      bedRoomCount: 0, // default value
      hasStudyRoom: false, // default value
      isPremium: false, // default value
      isPrivateLift: false, // default value
      isCompact: false, // default value
      isCompactPlus: false, // default value
      isDuplex: false, // default value
      isPenthouse: false, // default value
      isUtility: false, // default value
      hasGuest: false, // default value
    };
  };

  const overallRow = calculateOverallRow();

  return (
    <Section id={id} className={cn("overflow-x-hidden", className)}>
      <ProjectUnitMix
        title={t(title)}
        description={t(description, {
          total: calculateOverallRow()?.totalUnits ?? 0,
        })}
        unitMixData={
          overallRow ? [...project.unitTypes, overallRow] : project.unitTypes
        }
        totalUnits={project.totalUnits}
      />
    </Section>
  );
};

export default SectionProjectUnitMix;
