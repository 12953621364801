import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { HorizontalTable } from "~/components/atoms/Table";
import { Column } from "~/components/atoms/Table/types";
import Typo from "~/components/atoms/Typo";
import { TUnitType } from "~/entities/unitType";
import { cn } from "~/utilities/cn";
import { ETypoColor } from "~/utilities/enums/Colors";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";
import { formatRangeValue } from "~/utilities/helpers/formatRangeValue";
import roundUpNumber from "~/utilities/helpers/roundUpNumber";

export interface ProjectUnitMixProps {
  className?: string;
  unitMixData: TUnitType[];
  title: string;
  description?: string;
  totalUnits: number;
}

const ProjectUnitMix: React.FC<ProjectUnitMixProps> = ({
  className,
  unitMixData,
  title,
  description,
  totalUnits,
}) => {
  const { t } = useTranslation();
  const tableTranslator = "project.sections.unit_mix.table.header_name";

  const COLUMNS: Column[] = useMemo(
    () => [
      {
        headerName: t(`${tableTranslator}.unit_type`),
        field: "name",
        renderCell: (row) => (
          <Typo
            tag={ETypoTag.SPAN}
            variant={ETypoVariant.BODY_TITLE_16}
            color={ETypoColor.TABLE_TEXT}
          >
            {row?.title ?? ""}
          </Typo>
        ),
      },
      {
        headerName: t(`${tableTranslator}.total_units`),
        field: "totalUnits",
        headerCellClassName: "text-center",
        cellClassName: "text-center",
        renderCell: (row) => (
          <Typo
            tag={ETypoTag.SPAN}
            variant={ETypoVariant.BODY_TITLE_16}
            color={ETypoColor.TABLE_TEXT}
          >
            {row?.totalUnits}
          </Typo>
        ),
      },
      {
        headerName: t(`${tableTranslator}.size`),
        field: "size",
        headerCellClassName: "text-center",
        cellClassName: "text-center",
        renderCell: (row) => (
          <Typo
            tag={ETypoTag.SPAN}
            variant={ETypoVariant.BODY_TITLE_16}
            color={ETypoColor.TABLE_TEXT}
          >
            {formatRangeValue(row?.minArea ?? 0, row?.maxArea ?? 0)}
          </Typo>
        ),
      },
      {
        headerName: t(`${tableTranslator}.unit_mix`),
        field: "unitMix",
        headerCellClassName: "text-right",
        cellClassName: "text-right",
        renderCell: (row) =>
          totalUnits ? (
            <Typo
              tag={ETypoTag.SPAN}
              variant={ETypoVariant.BODY_TITLE_16}
              color={ETypoColor.TABLE_TEXT}
            >
              {roundUpNumber((row?.totalUnits / totalUnits) * 100, 1)}%
            </Typo>
          ) : (
            <></>
          ),
      },
    ],
    [t, tableTranslator, totalUnits]
  );

  return (
    <div
      className={cn(
        "lg:px-none flex flex-col gap-y-6 overflow-hidden rounded-xl py-6 lg:gap-y-5 lg:rounded-lg lg:py-4.5",
        className
      )}
    >
      <div className="flex items-center justify-between px-0 lg:px-6">
        <div className="space-y-2">
          <Typo
            className="font-bold"
            variant={ETypoVariant.HEADER_PROJECT_24}
            tag={ETypoTag.H3}
            color={ETypoColor.PROJECT_HEADING}
          >
            {title}
          </Typo>
          {description && (
            <Typo variant={ETypoVariant.SUB_TITLE_16} tag={ETypoTag.P}>
              {description}
            </Typo>
          )}
        </div>
      </div>

      <HorizontalTable
        classNames={{
          row: "*:last:font-bold",
          wrapper: "lg:px-6",
        }}
        columns={COLUMNS}
        rows={unitMixData}
        stickyRows
        variant="project"
      />
    </div>
  );
};

export default ProjectUnitMix;
