import { useState } from "react";
import { useTranslation } from "react-i18next";

import ModalMediaGallery from "../ModalMediaGallery";

import Button from "~/components/atoms/Button";
import { Icon } from "~/components/atoms/Icons";
import { HorizontalTable } from "~/components/atoms/Table";
import { Column } from "~/components/atoms/Table/types";
import Typo from "~/components/atoms/Typo";
import { IFloorPlan } from "~/entities/floorPlan";
import { TMedia } from "~/entities/media";
import { cn } from "~/utilities/cn";
import { ETypoColor } from "~/utilities/enums/Colors";
import { EIcon } from "~/utilities/enums/Icons";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";
import formatCurrencyRange from "~/utilities/helpers/formatCurrencyRange";

export interface ProjectFloorPlansProps {
  className?: string;
  floorPlanData: IFloorPlan[];
  page?: number;
  isLoading?: boolean;
  onLoadMore?: () => void;
}

const ProjectFloorPlans: React.FC<ProjectFloorPlansProps> = ({
  className = "",
  floorPlanData,
  page,
  isLoading,
  onLoadMore,
}) => {
  const { t } = useTranslation();
  const [galleryData, setGalleryData] = useState<{
    open: boolean;
    galleryImage: TMedia;
  }>({
    open: false,
    galleryImage: {} as TMedia,
  });

  const tableTranslator = "project.sections.floor_plans.table.header_name";

  const COLUMNS: Column[] = [
    {
      field: "unitType",
      headerName: t(`${tableTranslator}.unit_type`),
      renderCell: (row) => (
        <Typo
          variant={ETypoVariant.BODY_TITLE_16}
          color={ETypoColor.TABLE_TEXT}
          tag={ETypoTag.P}
        >
          {row?.unitType?.title}
        </Typo>
      ),
    },
    {
      field: "floorplan",
      headerName: t(`${tableTranslator}.floor_plan`),
      headerCellClassName: "text-center",
      cellClassName: "text-center py-2",
      renderCell: (row) => (
        <Button
          variant="outline"
          size="wide"
          onClick={() => {
            setGalleryData({
              open: true,
              galleryImage: row?.photo || ({} as TMedia),
            });
          }}
          className="w-full rounded border-tableText"
        >
          <div className="flex gap-1">
            <Icon className="size-[17px]" TypeIcon={EIcon.FloorPlanIcon} />
            <Typo
              tag={ETypoTag.SPAN}
              variant={ETypoVariant.BODY_TITLE_16}
              color={ETypoColor.TABLE_TEXT}
            >
              {row?.name}
            </Typo>
          </div>
        </Button>
      ),
    },
    {
      field: "area",
      headerName: t(`${tableTranslator}.size`),
      headerCellClassName: "text-center",
      cellClassName: "text-center",
      renderCell: (row) => (
        <Typo
          className="font-normal"
          tag={ETypoTag.SPAN}
          variant={ETypoVariant.BODY_TITLE_16}
          color={ETypoColor.TABLE_TEXT}
        >
          {row?.area || 0}
        </Typo>
      ),
    },
    {
      headerName: t(`${tableTranslator}.psf`),
      field: "psf",
      headerCellClassName: "text-center",
      cellClassName: "text-center",
      renderCell: (row) => (
        <Typo
          tag={ETypoTag.SPAN}
          variant={ETypoVariant.BODY_TITLE_16}
          color={ETypoColor.TABLE_TEXT}
          className="whitespace-nowrap"
        >
          {(row?.availableUnits || 0) === 0
            ? t("sold_out")
            : formatCurrencyRange(row?.minPSF ?? 0, row?.maxPSF ?? 0)}
        </Typo>
      ),
    },
    {
      headerName: t(`${tableTranslator}.price`),
      field: "price",
      headerCellClassName: "text-right",
      cellClassName: "text-right",
      renderCell: (row) => (
        <Typo
          tag={ETypoTag.SPAN}
          variant={ETypoVariant.BODY_TITLE_16}
          color={ETypoColor.TABLE_TEXT}
          className="whitespace-nowrap"
        >
          {(row?.availableUnits || 0) === 0
            ? t("sold_out")
            : formatCurrencyRange(row?.minPrice ?? 0, row?.maxPrice ?? 0)}
        </Typo>
      ),
    },
  ];

  return (
    <div className={cn("overflow-hidden", className)}>
      <HorizontalTable
        classNames={{
          wrapper: "lg:px-6",
        }}
        columns={COLUMNS}
        rows={floorPlanData}
        page={page}
        onLoadMore={onLoadMore}
        infinitiLoad
        isLoading={isLoading}
        stickyRows
        variant="project"
      />

      <ModalMediaGallery
        isOpen={galleryData.open}
        closeModal={() => {
          setGalleryData({
            galleryImage: {} as TMedia,
            open: false,
          });
        }}
        medias={[galleryData.galleryImage]}
      />
    </div>
  );
};

export default ProjectFloorPlans;
